<template>
  <b-card>
    <b-card-body
      class="mt-0 mb-0"
      style="padding: 1.1rem;"
    >
      <b-card-title
        class="mb-0 mt-0 pt-0"
      >
        <div class="d-flex">
          <h1 class="mb-1 ml-50">
            Details Information
          </h1>
        </div>
      </b-card-title>
    </b-card-body>
    <b-row>
      <b-col
        cols="21"
        xl="5"
        class="d-flex justify-content-between flex-column mb-1"
      >
        <table class="mt-2 mt-xl-0 w-100 ml-2">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ doctorData.Email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FileIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">NationalId</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ doctorData.NationalId }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="TrendingUpIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Gender</span>
            </th>
            <td>
              {{ doctorData.Gender.Name }}
            </td>
          </tr>
          <br>
          <tr>
            <th>
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address</span>
            </th>
            <td dir="auto">
              {{ doctorData.Address }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td>
              {{ doctorData.Country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="ArrowUpRightIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">City</span>
            </th>
            <td>
              {{ doctorData.City }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="AlertCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Area</span>
            </th>
            <td>
              {{ doctorData.Area }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import doctorStoreModule from '@/store/main/DoctorStoreModule'

export default {
  components: {
    BCard, BRow, BCol, BCardBody, BCardTitle,
  },
  props: {
    doctorData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const DOCTOR_APP_STORE_MODULE_NAME = 'doctor-store'
    // Register module
    if (!store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.registerModule(DOCTOR_APP_STORE_MODULE_NAME, doctorStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCTOR_APP_STORE_MODULE_NAME)
    })

    function deleteDoctor(doctor) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mr-1',
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this doctor ${doctor.FullName}!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('doctor-store/deleteDoctor', { id: doctor.Id })
            .then(() => {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              )
              this.$router.push({ name: 'doctor-list' })
            })
            .catch(() => {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Error happenied when deleting doctor',
                'error',
              )
            })
        } else if (result.dismiss === 'cancel') {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error',
          )
        }
      })
    }

    const resolveDoctorVariant = () => {
      switch (Math.floor(Math.random() * (4 - 0)) + 0) {
        case 0: return 'primary'
        case 1: return 'warning'
        case 2: return 'success'
        case 3: return 'info'
        case 4: return 'danger'
        default: return 'primary'
      }
    }
    return {
      avatarText,
      resolveDoctorVariant,
      deleteDoctor,
    }
  },
}
</script>

<style>

</style>
