import axios from '@axios'

export default {
  namespaced: true,
  state: {
    specializations: [],
  },
  getters: {},
  mutations: {
    SET_SPECIALIZATIONS(state, specializations) {
      state.specializations = specializations
    },
  },
  actions: {
    getAllDoctors(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/doctors', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDoctorsCount() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/doctors/count')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDoctorById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/doctor/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDoctor(ctx, addDoctorObject) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/doctor/add', addDoctorObject)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllSpecializations({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/specializations')
          .then(response => {
            if (response.status === 200 && response.data.IsSucceeded === true) {
              const specializations = []
              response.data.Data.List.forEach(element => {
                specializations.push({
                  id: `${element.Id}-${element.Name}`,
                  label: element.Name,
                  value: element.Id,
                })
              })
              commit('SET_SPECIALIZATIONS', specializations)
              resolve(specializations)
            }
          })
          .catch(error => reject(error))
      })
    },
    deleteDoctor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/doctor/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDoctorBasicInfo(ctx, updateDoctorBasicInfoObject) {
      return new Promise((resolve, reject) => {
        // console.log(updateDoctorBasicInfoObject)
        axios
          .put('/api/doctor/edit_basic_info', updateDoctorBasicInfoObject)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDoctorDetailsInfo(ctx, updateDoctorDetailsInfoObject) {
      return new Promise((resolve, reject) => {
        // console.log(updateDoctorDetailsInfoObject)
        axios
          .put('/api/doctor/edit_details_info', updateDoctorDetailsInfoObject)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
